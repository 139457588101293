import React from "react"
import themeConfig from "../configs/themeConfig"
import classnames from "classnames"

const FullPageLayout = ({ children, ...rest }) => {
  return (
    <div
      className={classnames(
        //"full-layout wrapper bg-full-screen-image blank-page dark-layout",
        "full-layout wrapper blank-page dark-layout",
        {
          "layout-dark": themeConfig.layoutDark
        }
      )}
    >
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <div style={{
              minHeight: 100 + 'vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}>
              <main className="main w-100 pt-4 pb-4">{children}</main>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullPageLayout
